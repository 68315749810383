/*------------------------------------tablette--------------------------*/


@media(min-width: 700px) {

    .title--big {
      font-size: 54px;
      
    }
  
    .title__projet {
      padding: 0;
      pointer-events: none;
  
  
    }
  
    .slider-container {
      width: 400%;
    }
  
  
    .project__block .project__image {
      width: 100%;
      height: 300px;
    }
  
    .project__block {
      width: 45%;
      height: max-content;
    }
  
    .descrip__details {
  
      flex-direction: row;
      align-items: center;
    }
  
  
    .info {
      padding: 30px;
    }
  
    .technologies {
      margin-top: 0;
      margin-left: 50px;
    }
  
  
    .window {
      padding: 5rem;
    }
  
  
    .containerInformation {
      flex-direction: row;
    }
  
    .containerLine {
      content: "";
      width: 17px;
      height: 65vh;
      background-image: url(../assets/images/line.svg);
      background-position: bottom center;
      background-repeat: no-repeat;
      position: absolute;
      z-index: -1;
      opacity: 0;
  
    }
  
    .profil {
      position: relative;
      left: 150px;
    }
  
    .contanerImg__profil {
      width: 300px;
    }
  
    .contanerImg__competence {
      width: 400px;
    }
  
    .title--qui {
      position: relative;
      left: 45px;
      font-size: 61px;
    }
  
    .header__title {
      font-size: 23px;
    }
  
    .project__image--popup {
      width: 100%;
      height: 300px;
    }
  
    .technologies__liste {
      gap: 15px;
    }
  
   
  
    .menu {
  
     margin-left: 15px;
    
    }
  
    .container__projets {
      gap: 20px;
    }
  
  
  }
  
  

  /*------------------------------------destop--------------------------*/
  
  
  @media(min-width: 900px) {
  
  
    .sectionCompetence {
      grid-column: 2 / 4;
    }


    .title--engagement {
      font-size: 61px;
      transform: translateX(150px);
    }
  
  
  
    .project__block {
      width: 30%;
    }
  
    .info {
      padding: 20px;
    }
  
    .contanerImg__profil {
      width: 400px;
    }
  
    .containerLine {
      height: 85vh;
    }
  
  
  }
  
  
  
  @media(min-width: 2000px) {
  
  
    .container {
      margin: 0 auto;
      max-width: 2000px;
    }

    .title--engagement {
      transform: translateX(120px);
    }
  
  
    .containerLine {
      height: 39vh;
    }
  
  }


  @media(min-width: 3000px) {

  
  
    .containerLine {
      height: 20vh;
    }
  
  }
/*------------------------------------poppup--------------------------*/

.popup-modal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 100vw;
    border-radius: 10px;
    background-color: #1d4e4e;
    justify-content: center;
    z-index: 9999;
  }
  
  .window {
    padding: 1rem;
  
  }
  
  .popup-modal--visible {
    display: flex;
    max-width: 600px;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .project__image--popup {
    width: 100%;
    height: 190px;
    border-radius: 15px;
  }
  
  
  .margin--botton {
    margin-bottom: 15px;
  }
  
  
  .technologies__liste {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 21px;
    font-size: 16px;
    max-width: 520px;
    justify-content: center;
    gap: 6px;
  
  
  
    .technologie__el {
      background-color: #025e73;
      font-weight: bold;
      padding: 6px;
      color: white;
      display: flex;
      align-items: center;
  
      span {
        margin-left: 5px;
      }
    }
  }
  
  
  .visiteSite {
    background-color: #fff;
    padding: 10px;
    color: #06593B;
    font-weight: 900;
    border-radius: 5px;
  
    &:hover {
      opacity: 0.5;
    }
  }
  
  .info {
    padding: 10px;
  }
  
  .technologies {
    margin-top: 30px;
  }
  
  .descrip__details {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  
  .img__projet--detail {
    width: 200px;
    margin-top: 50px;
  }
  
  .img__details {
    margin-top: 40px;
  }
  
  .btn__connexion {
  
    border: none;
    background-color: #025e73;
    padding: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    
  
    
  }
  
  .btn__connexion:hover{
    background-color: #B21109;
    transition: background-color 0.3s ease, transform 0.3s ease;
    opacity: 0.5;
  }
/*------------------------------------section Competence --------------------------*/



.p--marg {
    margin-top: 22px;
  }
  
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sectionCompetence {
    height: 100vh;
    width: 100%;
    justify-content: center;
    gap: 20px;
  }
  
  .contanerImg__competence {
    width: 304px;
    height: 100%;
  }
  
  
  
  .email__button {
    display: inline-block;
    padding: 5px;
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #fff;
  
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border: solid 1px #025e73;
  }
  
  .email__button:hover {
  
    transform: scale(1.05);
  }
  
  .email__button:active {
    transform: scale(1.05);
  
  }
  
  
  
  .title--position {
    margin-top: 79px;
    text-align: center;
  }
  
  .section--marg {
  
    margin-top: 120px;
  }
  
  .headAnchor {
    position: relative;
    display: inline-flex;
    text-indent: 99%;
    white-space: nowrap;
    overflow: hidden;
    height: 100vh;
    margin-left: 60px;
  }
  
  .headAnchor__arrow {
    height: 8vh;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-name: arrow;
  }
  
  
  
  @keyframes arrow {
    0% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(0px, 10px);
    }
  
    100% {
      transform: translate(0, 0);
    }
  }
  
  /* ----------------------- Annimation ----------------------- */
    
  @keyframes moveCompetence {
    0% {
      
      opacity: 0;
    }
  
    100% {
     
      
      opacity: 1;
    }
  }
  
  .show-competence {
    opacity: 0;
    animation: moveCompetence 3s forwards;
  }
  
  .web {
    animation-delay: 0.5s;
  }
  
  .ui {
    animation-delay: 1s;
  }
  
  .illu {
    animation-delay: 1.5s;
  }
  
  .nw {
    animation-delay: 2s;
  }
  
  .inf {
    animation-delay: 2.5s;
  }
  
  
  
  
  
  
  /*------------------------------------section profil --------------------------*/
  
  .containerInformation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }
  
  
  
  .contanerImg__profil {
    width: 250px;
  }
  
  /* ----------------------- Annimation ----------------------- */
    
  @keyframes lineScale {
    from {
     opacity: 0;
      transform: translateX(-50%) scaleY(0);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) scaleY(1);
    }
  }
  
  .show-fleche {
    opacity: 1;
    animation: lineScale 1s ease forwards;
  }
  
  @keyframes showProfil {
    0% {
      
      opacity: 0;
    }
  
    100% {
     
      
      opacity: 1;
    }
  }
  
  .show-profil {
   
    animation: showProfil 3s forwards;
  }
  
  
  /*------------------------------------section projets --------------------------*/
  
  
  .container__projets {
    display: flex;
    justify-content: center;
    height: -webkit-max-content;
    flex-wrap: wrap;
    width: 90%;
  }
  
  
  .projet__el {
    width: max-content;
    margin-top: 50px;
    position: relative;
    overflow: hidden;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-name: arrow;
  }
  
  .img--position {
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background: round;
  }
  
  .project__image--tfe {
    background-image: url(../assets/images/tfe.png);
  
  }
  
  .project__image--ilab {
    background-image: url(../assets/images/ilab.png);
  
  }
  
  .project__image--noid {
    background-image: url(../assets/images/no-id.png);
  }
  
  .project__image--univers {
    background-image: url(../assets/images/theunivers.png);
  }
  
  .project__image--tfa {
    background-image: url(../assets/images/tfa.jpg);
  }
  
  
  .project__image--dc {
    background-image: url(../assets/images/dataplay.jpg);
  }
  
  .project__image--rux {
    background-image: url(../assets/images/rux.jpg);
  }
  
  .project__image--iolce {
    background-image: url(../assets/images/iolce.jpg);
  }
  
  .project__image--hw {
    background-image: url(../assets/images/hw.jpg);
  }
  
  .project__block {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    
  
    .project__header {
      background: #1d4e4e;
      padding: 12px 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;
  
      .header__dots {
        display: flex;
        gap: 6px;
  
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.5);
        }
  
        .dot1{
          background-color:#46ffbb;
        }
        
        .dot2{
          background-color:#f5c670;
        }
        
        .dot3{
          background-color: #a50301;
        }
      }
  
  
    }
  
    .project__image {
      width: 100%;
      height: 200px;
    }
  
    .project__content {
      padding: 20px;
      background: #1d4e4e;
    }
  }
  
  .project__image {
    width: 100%;
    height: 160px;
  }
  
  .header__title {
    color: white;
    font-size: 16px;
    margin: 0;
  }
  
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #025e73bd;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    cursor: pointer;
  }
  
  .cross {
    font-size: 4rem;
    color: white;
  }
  
  .projet__el:hover .img__projet {
    transform: scale(1.05);
  }
  
  .projet__el:hover .overlay {
    opacity: 1;
  }
  
  
  
  
  .sectionProjets {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .title__projet {
  
    margin-top: 7px;
    color: #a50301;
  
  
  }
  
  .container__contact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .title--engagement {
      transform: translateX(60px);
      opacity: 0;
      transition: all 1s ease-out;
    }

    .title--engagement.visible {
      transform: translateX(0);
      opacity: 1;
    }
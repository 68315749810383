/*------------------------------------Header--------------------------*/

.head__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .title--big {
    font-size: 28px;
    text-align: center;
    padding: 30px;
  }
  
  
  .reseau__el {
  
    position: relative;
    transition: transform 0.3s ease;
  
    cursor: pointer;
    width: 60px;
    height: 60px;
    text-align: center;
  
  
  
    &:hover {
      transform: scaleX(25px);
    }
  
  
    .picto__el {
      width: 100%;
      height: 100%;
      padding: 11px;
    }
  
  
    &::before {
      content: "Linkedin";
      position: absolute;
      top: 25%;
      left: 30%;
      transform: translateX(50%);
      background-color: #025e73;
      color: #fff;
      padding: 5px;
      font-size: 16px;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 999;
      border-radius: 5px;
    }
  
    &:hover::before {
      opacity: 1;
    }
 
  }
  
  .reseau__el--content {
  
    &::before {
      content: "tchamojodrick@gmail.com";
      left: 0;
      margin-left: -45px;
    }
  }
  
  
  
  .menu {
  
    position: fixed;
    bottom: 0;
    transition: .5s;
    left: 0;
    z-index: 999;
    border-radius: 3px;
  
  }
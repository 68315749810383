@import 'utils/variables';
@import 'components/type';
@import '../../node_modules/modern-normalize/modern-normalize.css';
@import '../styles/components/header.scss';
@import '../styles/components/section.scss';
@import '../styles/components/popup.scss';
@import '../styles/components/footer.scss';
@import '../styles/components/mediaQuery.scss';

* {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden !important;
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
}

body {
  font-family: "Sedan", serif;
  line-height: 1.4;
  font-size: 18px;
  color: #fff;
  background-image: url(../assets/images/background.svg);
  background-repeat: no-repeat;
  background-size: cover;



}

p,
figcaption {
  max-width: 55ch;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #f5deb3;

}

.title {
  color: #f5deb3;
}



.title--medium {
  font-size: 54px;
}

.main {
  display: flex;
  flex-direction: column;
  padding: 35px;


}
























